// utils/rem.js
/* 字体大小 */
const baseSize = 100 / 2
/* 屏幕大小 */
const baseWidth = 375
const setHtmlFontSize = () => {
  const windowWidth = window.innerWidth
  document.querySelector('html').style.fontSize = windowWidth / baseWidth * baseSize + 'px'
}
setHtmlFontSize()
window.addEventListener('resize', setHtmlFontSize, false)
